import React from "react";
import { Carousel } from 'react-bootstrap';

function Partenaire() {
  const images = [
    "images/partenaire/aiyi-logo.jpg",
    "images/partenaire/alre-logo.png",
    "images/partenaire/amira-logo.png",
    "images/partenaire/asa-logo.jpg",
    "images/partenaire/beckhoff.png",
    "images/partenaire/cybro_logo.png",
    "images/partenaire/ddtop-logo.jpg",
    "images/partenaire/delta-mobrey-logo-1.png",
    "images/partenaire/dwyer-logo.png",
    "images/partenaire/emtek.jpg",
    "images/partenaire/georgin-logo.png",
    "images/partenaire/goodway-logo.png",
    "images/partenaire/lascar.png",
    "images/partenaire/logo-powtech.gif",
    "images/partenaire/mirkip.jpg",
    "images/partenaire/msa-logo.png",
    "images/partenaire/ngm-logo_00d5005a0_5208.jpg",
    "images/partenaire/nuova-fima-logo.png",
    "images/partenaire/plastomatic-logo1.jpg",
    "images/partenaire/sika-logo.png",
    "images/partenaire/sothis-logo.jpg",
    "images/partenaire/teat-logo.png",
    "images/partenaire/topas-logo.jpg",
    "images/partenaire/tsi-logo.png"
  ];

  const renderCarouselItems = () => {
    const imagesPerPage = 3;
    const carouselItems = [];

    for (let i = 0; i < images.length; i += imagesPerPage) {
      const pageImages = images.slice(i, i + imagesPerPage);
      const carouselItem = (
        <Carousel.Item key={i}>
          <div className="container">
            <div className="row row-pb-md ">
              {pageImages.map((image, index) => (
                <div key={index} className="col mb-4">
                  <img
                    className="img-fluid"
                    src={image}
                    alt={`Slide ${i + index + 1}`}
                    style={{ width: '80%', height: '100px' }} 
                  />
                </div>
              ))}
            </div>
          </div>
        </Carousel.Item>
      );
      carouselItems.push(carouselItem);
    }

    return carouselItems;
  };

  return (
    <Carousel controls={true} interval={5000}>
      {renderCarouselItems()}
    </Carousel>
  );
}

export default Partenaire;
