import './App.css';
import React, { useState } from 'react';
import { rgba } from 'polished';
import Contact from './pages/contact';
import Partenaire from './components/partenaire';
import Footer from './components/footer';

function App() {


  const [afficherDiv, setAfficherDiv] = useState(false);

  const toggleDiv = () => {
    setAfficherDiv(!afficherDiv);

  };
  //
  const [visibleMaterials, setVisibleMaterials] = useState(16);
  const loadMore = () => {
    setVisibleMaterials((prevVisibleMaterials) => prevVisibleMaterials + 12);
  };

  //
  const loadLess = () => {
    setVisibleMaterials((prevVisibleMaterials) => Math.max(12, prevVisibleMaterials - 12));
  };

  const materiels = [

    { nameImg: "Régulateur de débit massique de gaz", img: "images/image/300.jpg", description: "Régulateur de débit massique de gaz à contrôleur numérique DWYER GFCUtilise des électrovannes et des composants électroniques PID (proportionnel intégral) pour fournir un contrôle continu en comparant la valeur mesurée du signal du capteur pour définir les paramètres de débit. Application : Gaz propres compatibles avec les pièces en contact avec le fluide. Fabriqué aux Etats-Unis. Fabricant DWYERprix sur demande " },
    { nameImg: "Rotamètre pour gaz et liquides ", img: "images/image/301.jpg", description: "Fournit une surveillance et un contrôle continus des niveaux de débit. L'alarme de débit peut être configurée pour ouvrir ou fermer un contact afin d'augmenter ou de diminuer le point de consigne. Applications : Processus chimiques, traitement des eaux usées, contrôle des processus, systèmes d'énergie solaire, tests de canalisations d'évacuation et de pompes.    " },
    { nameImg: "Débitmètre massique de gaz et de liquide ", img: "images/image/302.jpg", description: "Mesure du débit massique, de la température et de la densité. Diamètre : 15 mm. Débit maximum : 12 t/h (50 kg/min). Pression de fonctionnement maximale - 4 MPa. Protection : IP65/IP67. Température du liquide : -70°C~+160°C, -200~+60ºC, -40~+300ºC. Sortie : RS485 Modbus RTU, HART, 4-20 mA, impulsion. Matériaux en contact avec le produit : acier inoxydable 316L    " },
    { nameImg: "zOrdinateur de flux DDTOP FC2000-TBIAH    ", img: "images/image/303.jpg", description: "DDTOP FC2000-TBIAH est un ordinateur de débit multifonctionnel qui peut être adapté à divers débitmètres et environnements de test, et possède de nombreuses fonctions telles que la compensation dynamique complète des paramètres de débit, le stockage des données historiques, le contrôle comptable, le règlement commercial et la communication réseau pour atteindre le volume de mesure. et le débit massique.   " },
    { nameImg: "Dwyer DPGWB Manomètre numérique pour liquides et gaz    ", img: "images/image/304.jpg", description: "Le seul manomètre de gaz numérique économique avec unités d'ingénierie sélectionnables. Avec une précision de 0,5 % (1 %) et un bouton zéro, c'est un bon choix pour la surveillance numérique.    " },
    { nameImg: "Manomètre différentiel Bluetooth sans fil   ", img: "images/image/305.jpg", description: "Le transmetteur de pression différentielle sans fil série DP3 est un transmetteur de pression différentielle compact, très précis et à sélection automatique, idéal pour les applications à faible débit.    " },
    { nameImg: "Manomètre pour fluide sanitaire NUOVA FIMA SP    ", img: "images/image/306.jpg", description: "SP – manomètre sanitaire, le corps est en acier inoxydable, peut être traité en autoclave. Diamètre du boîtier DS 2,5, 4 (63-100 mm). Plage de mesure : 0...15 psi à 0...600 psi (0...1 bar à 0...40 bar ou unités équivalentes) ; Degré de protection : IP67 ; Classe de précision : A selon ASME B40.1 ; Connexion : 1″ 1/2 CLAMP (ISO 2852). PINCE 2″ (ISO 2852)." },
    { nameImg: "Pompe d'étalonnage basse pression Dwyer BCHP    ", img: "images/image/307.jpg", description: "La pompe de test d'étalonnage de la série BCHP crée une pression et un vide pour régler ou calibrer les manomètres, les capteurs ou les interrupteurs. La pompe est à commande manuelle et a une plage de pression pneumatique de -28 inHg. Art. jusqu'à 870 psi pouce (-0,95 bar à 60 bar).    " },
    { nameImg: "Pompe d'étalonnage Dwyer A-396A    ", img: "images/image/308.jpg", description: "La pompe d'étalonnage A-396A sert de source de pression pour étalonner les manomètres et les capteurs, ainsi que pour régler les pressostats. Utilisé avec un manomètre ou un autre étalon de pression. Application : étalonnage de manomètres, relais, capteurs et enregistreurs de pression.    " },
    { nameImg: "Manomètre industriel en acier inoxydable   ", img: "images/image/309.jpg", description: "Fonctionnent sur une large gamme de plages allant du vide complet à 69 bars et sont disponibles en conceptions radiales ou axiales. Conçu avec des corps en acier inoxydable 304 CC et des pièces de contact en laiton pour une compatibilité chimique supérieure.   " },
    
    { nameImg: "SÉRIE DPG MANOMÈTRE NUMÉRIQUE", img: "images/image/w1.jpg", description: "SÉRIE DPG MANOMÈTRE NUMÉRIQUE Précision de ±0,25 % ou ±0,5 % de la pleine échelle, boîtier en aluminium NEMA 4X (IP66) Les manomètres numériques de la série DPG ont une précision élevée de ±0,25 % ou ±0,5 % en pleine échelle. L'affichage numérique à 4 chiffres réduit le risque d'erreur de lecture en éliminant l'erreur de parallaxe couramment produite par les jauges analogiques. La série DPG est alimentée par des piles et dispose d'un système d'arrêt automatique pour préserver la durée de vie des piles. La durée de vie des piles est en moyenne de 2000 heures. Un clavier à 4 touches permet d'accéder facilement aux fonctions sans devoir passer par des menus complexes ou des combinaisons de touches difficiles. Ces fonctions comprennent le rétro-éclairage, le pic et la vallée, la tare ou le zéro automatique et la conversion des unités de pression.L'ÉTALONNAGE NIST est disponible dans le configurateur de produits.Prix sur demande Délai de livraison à partir de 8 semaines"},
    { nameImg: "DWYER SF2 Capteur de débit d'eau", img: "images/image/w2.jpg", description: "DWYER SF2 Capteur de débit d'eau avec relais à contact électrique Combine un indicateur visuel avec une roue d'écoulement et un relais ou une sortie d'impulsion. La structure de support en laiton, le rotor composite d'une seule pièce et l'arbre en céramique permettent de gérer avec succès une large gamme de produits chimiques, de températures et de pressions. Application : Surveillance visuelle et contrôle du débit des liquides. Fluide : Liquides compatibles avec les pièces en contact avec le fluide ; Limite de température : -29 à 100°C ; Limite de pression : 13,8 bar (200 psig) à 70°F. Précision : Sortie relais : ±5%. Sortie d'impulsion : ±7% pour les gammes jusqu'à 5,0 gpm, ±15% pour les gammes jusqu'à 60,0 gpm. Signal de sortie : SPDT : 1A, 24 VDC résistif ; 0,3A, 110 VAC ou 4,5 à 24 VDC impulsionnel selon le modèle.Connexions électriques : Modèles de sortie de relais : 20AWG PVC isolation, 24câble. Modèles à sortie d'impulsion : isolation PVC 22AWG, câble 24. Fabriqué aux Etats-Unis. Fabricant DWYER, Modèle SF2 Prix sur demande  Délai de livraison à partir de 8 semaines"},
    { nameImg: "ARTANG Aimag H - Débitmètre électromagnétique sanitaire", img: "images/image/w3.jpg", description: "ARTANG Aimag H - Débitmètre électromagnétique sanitaire pour le lait et les boissons Le débitmètre électromagnétique sanitaire est également appelé débitmètre magnétique hygiénique et débitmètre magnétique à trois pinces. Il s'agit d'un débitmètre alimentaire qui convient aux industries alimentaires, laitières, brassicoles et pharmaceutiques. Le débitmètre sanitaire peut répondre aux exigences en matière d'hygiène et fournir des mesures de débit précises et fiables. Application : industrie alimentaire hygiénique des boissons/médicaments/bière/lait/produits laitiers ; diamètre nominal : DN6 ~ DN150 ; raccordement : collier, bride, soudure. Pression de service : PN16 ~ 40 / CLASS 150/300 / JIS 10K/20K ; précision : ± 0,5 % / ± 0,2 % ; signal de sortie : fréquence/impulsion 4~20 mA ; degré de protection : IP67 / IP68 (en option). L'appareil est conforme aux exigences des règlements techniques de l'Union douanière sur la sécurité des équipements destinés à fonctionner dans des environnements explosifs (TR TS 012/2011). Fabriqué en Chine. Fabricant ARTANG, modèle Aimag H Prix sur demande Délai de livraison à partir de 8 semaines"},
    { nameImg: "DWYER Magnehelic 2000 manomètre différentiel air et gaz ", img: "images/image/w6.jpg", description: "DWYER Magnehelic 2000 manomètre différentiel air et gaz VENTE IMMÉDIATE Ces manomètres différentiels sont uniques en leur genre. Ils n'ont aucune liaison mécanique, ce qui leur confère une grande longévité. Ces manomètres sont homologués par l'Etat et bénéficient d'une garantie de 10 ans ! Mesure de la pression différentielle de 60Pa à 30kPa. Limite de pression de 5,5 bar. Applications : salles blanches, ventilation et autres applications. Air et gaz ininflammables, gaz compatibles (option gaz naturel disponible). Précision : ±2% pleine échelle (±3% pour - 0-100Pa, - 120Pa, 10MM et ±4% pour -00, -60Pa à 21,1°C). Limite de température : -6,67 à 60°C. Raccordements : 1/8″ double NPT femelle vers NPT mâle (filetages standard) et robinets haute et basse pression - une paire sur le côté et une paire à l'arrière. Orientation de montage : Diaphragme en position verticale, consulter le fabricant pour d'autres orientations. Prix sur demande  Délai de livraison à partir de 8 semaines"},
    { nameImg: "WINTERS WINSMART LY36 Capteur de pression différentielle intelligent antidéflagrant", img: "images/image/w7.jpg", description: "WINTERS WINSMART LY36 Capteur de pression différentielle intelligent antidéflagrant pour le pétrole et le gaz Capteur de pression différentielle intelligent et antidéflagrant à hautes performances pour les emplacements dangereux. Applications : surveillance de la pression différentielle du pétrole et du gaz, surveillance de la production des puits, usines pétrochimiques, équipements électriques et tous les emplacements dangereux. Limite de température : fonctionnement à partir de -40/+85°C (pour le modèle avec écran LCD -20/+70°C) ; stockage à partir de -40/+110°C (pour le modèle avec écran LCD -40/+85°C) ; écran : remplissage d'huile de silicone à partir de -40/+120°C. Humidité de fonctionnement : 5-100% à 40°C ; précision : 0,075% - version URL, 0,05% - en option. Degré de protection : IP66 / IP67. Fabriqué au Canada. Fabricant WINTERS Prix sur demande Délai de livraison à partir de 8 semaines"},
    { nameImg: "Manomètre numérique de référence pour liquides et gaz SIKA type L", img: "images/image/w8.jpg", description: "Manomètre numérique de référence pour liquides et gaz SIKA type L Conçu pour fonctionner dans des environnements industriels difficiles. Plage de mesure : de 1...2 bar à 0...1000 bar ; Classe de protection : IP65. Fluides sous pression et milieux gazeux ; connexion : G1/4″ filetage mâle, RS 485. Température du fluide : 0...50 C, température de fonctionnement : 0...50 C. Précision de mesure ± 0,025 % WL ± 1 chiffre mL ; amélioration de la précision ± 0,01 % WL ± 1 chiffre mL (pas pour le modèle (2)). Fabriqué en Allemagne. Fabricant SIKA Prix sur demande Délai de livraison à partir de 8 semaines"},

    { nameImg: "Compteur portable pour compter les particules d'aérosols", img: "images/image/200.jpg", description: "Compteur portable pour compter les particules d'aérosols avec imprimante intégrée. Les compteurs de particules TSI AEROTAK 9110 sont conçus pour les systèmes de surveillance des zones propres. Taux d'échantillonnage 28,3 l/min ; Mesure sur 8 canaux simultanément ; Les tailles des particules mesurées vont de 0,1 à 10 microns. Temps de fonctionnement continu 4 heures. La vérification et l’étalonnage du compteur à l’aide de notre équipement sont disponibles. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Compteur stationnaire pour détecter les particules d'aérosol avec télécommande", img: "images/image/201.jpg", description: "Compteur stationnaire pour détecter les particules d'aérosol avec télécommande (télécommande). Les compteurs de particules TSI AEROTAK 7110 sont conçus pour surveiller et valider les zones propres. Taux d'échantillonnage 28,3 l/min ; Mesure sur 8 canaux simultanément ; Les tailles des particules mesurées vont de 0,1 à 10 microns. La vérification et l’étalonnage du compteur à l’aide de notre équipement sont disponibles. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "compteurs de particules portables (APC) AeroTrak A100", img: "images/image/203.jpg", description: "Les compteurs de particules portables (APC) AeroTrak A100 sont idéaux pour les applications de certification, de surveillance, de test de gaz et de numérisation de filtres en salle blanche. Nouveauté 2023 ! Il dispose d'une interface mise à jour, d'une indication visuelle, du Wi-Fi et d'une fonction de test de filtre intégrée. Tailles de particules comprises entre 0,3 et 10 microns ou 0,5 et 10 microns. Les débits sont de 28,3 l/min, 50 l/min et 100 l/min. 6 canaux sélectionnables par l'utilisateur. L’étalonnage du compteur est disponible sur nos équipements. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "compteur de particules optique LAP 340/L", img: "images/image/204.jpg", description: "Le compteur de particules optique LAP 340/L est conçu pour analyser la distribution de taille et de concentration des particules dans les aérosols, mieux adapté aux tests de filtres (2,83 l/min).  Vitesse d'échantillonnage : 2,83 l/min ; plage de mesure de la taille des particules : de 0,2 ... 10 µm ; plage de mesure de la concentration en particules : max. 70/cm3. L’étalonnage du compteur est disponible sur nos équipements. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines"},
    { nameImg: "Compteur manuel pour l'enregistrement des particules", img: "images/image/205.jpg", description: "Les compteurs de particules MIRKIP H630 sont conçus pour la surveillance et la validation en salle blanche. Vitesse d'échantillonnage 2,83 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,3 à 5 microns. L’étalonnage du compteur est disponible sur nos équipements. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "compteur de particules PAM 510", img: "images/image/206.jpg", description: "Le compteur de particules PAM 510 est conçu pour analyser la taille moyenne des particules et la concentration en particules des aérosols monodispersés. Les générateurs d'aérosols à condensation SLG 270 de Topas produisent des aérosols monodispersés dont la taille varie de 0,2 à 8 µm. La taille et la concentration des particules peuvent être rapidement ajustées Plage de mesure de la taille des particules : de 0,5 à 10 µm ; plage de mesure de la concentration de particules : 10 000 ... 10 000 000/cm3 ; vitesse d'échantillonnage : 0,1 ... 8 l/min PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "photomètre d'aérosol PM-350 ", img: "images/image/207.jpg", description: "Le photomètre d'aérosol PM-350 est utilisé pour la détection des fuites sur site après l'installation du filtre efficace et de son système, principalement pour vérifier les petits trous et autres dommages dans le filtre tels que le joint du cadre, le joint et les fuites sur le cadre du filtre.Taux d'échantillonnage : 1 cfm (28,3 l/min) ± 10 %.  L’étalonnage du compteur est disponible sur nos équipements.PRIX SUR DEMANDE Délai de livraison : à partir d'une semaine" },
    { nameImg: "Comptoir portable avec imprimante intégrée", img: "images/image/208.jpg", description: "Comptoir portable avec imprimante intégrée. Possède une fonction de stockage de données.Vitesse d'échantillonnage 2,83 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,3 à 10 microns. L’étalonnage du compteur est disponible sur nos équipements.PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Compteur portable avec fonction de stockage de données, avec imprimante intégrée", img: "images/image/209.jpg", description: "Compteur portable avec fonction de stockage de données, avec imprimante intégrée. Dispose d'un écran LCD.Taux d'échantillonnage 28,3 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,3 à 5 microns. L’étalonnage du compteur est disponible sur nos équipements.PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Compteur portable avec capacité de mémoire", img: "images/image/m3.jpg", description: "Compteur portable avec capacité de mémoire : 5000 résultats de mesures. Il possède un corps en acier inoxydable SS304 et un écran tactile couleur de 7 pouces.Vitesse d'échantillonnage 50 l/min ; Mesure sur 8 canaux simultanément ; Les tailles des particules mesurées vont de 0,3 à 10 microns. L’étalonnage du compteur est disponible sur nos équipements.PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "pompes à vide à vanne papillon", img: "images/image/m7.jpg", description: "Ces pompes à vide à vanne papillon fonctionnant à sec, modèles V-VTN 16 à V-VTN 41, sont conçues pour pomper du gaz à partir de systèmes fermés. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Compteur stationnaire pour compter les particules", img: "images/image/m6.jpg", description: "Compteur stationnaire pour compter les particules. Peut produire un nombre de particules via 5 canaux de 0,3 μm, 0,5 μm, 1,0 μm, 5,0 μm et 10 μm simultanément en temps réel. Taux d'échantillonnage 28,3 l/min ; Mesure sur 5 canaux simultanément ; L'inscription au registre national du SI de la Fédération de Russie est prévue pour mai 2024. L’étalonnage du compteur est disponible sur nos équipements. Interface en russe. Capteur de température/humidité et capteur de débit intégrés avec prise en charge Modbus. Pompe à vide intégrée. Échantillonneur isocinétique inclus. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Compteur portable de détection de particules d'aérosol avec imprimante intégrée", img: "images/image/m5.jpg", description: "Compteur portable de détection de particules d'aérosol avec imprimante intégrée. Les compteurs de particules TSI AEROTAK 9500 sont conçus pour les systèmes de surveillance des salles blanches. Vitesse d'échantillonnage 100 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,5 à 25 microns. Temps de fonctionnement continu 3 heures. La vérification et l’étalonnage du compteur à l’aide de notre équipement sont disponibles. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "compteur de particules FMS 375 avec dilution et changement d'échantillon", img: "images/image/m1.jpg", description: "Le compteur de particules FMS 375 avec dilution et changement d'échantillon est conçu pour déterminer l'efficacité des séparations fractionnaires, par ex. séparateurs ou filtres à brouillard d'huile. Vitesse d'échantillonnage : 3 l/min ; plage de mesure de la taille des particules : de 0,2 ... 40 µm ; débit d'analyse : 0,1 l/min. Alimentation en air comprimé : de 4 ... 8 bars ; pression ambiante : +/- 0,8 bar ; température de l'aérosol : max. 120°C. Connecteur d'échantillon : 8 mm (diamètre extérieur). PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines"},
    { nameImg: "DWYER PUB débitmètre à ultrasons pour liquides homogènes", img: "images/image/m4.jpg", description: "Capable de fonctionner en continu pendant 20 heures grâce à la batterie intégrée. Utilise une méthode sans contact pour mesurer les débits dans les tuyaux en fonction de la différence de temps d'écoulement. Un appareil compact et léger avec une électronique intégrée de dernière génération pour le traitement du signal, avec des performances élevées et une facilité d'utilisation. Application : liquides homogènes ne contenant pas de bulles d’air, capables de propager des ondes ultrasonores. Plage : 0,1 à 20 m/s ; limite de température : de -20 à 135°C ; signal d'entrée : le câble de connexion Lemo provient des capteurs ; signal de sortie : analogique, impulsion. Niveau de protection : convertisseur : IP54 ; capteurs : IP51 Prix sur demande  Délai de livraison : à partir de 8 semaines" },
    { nameImg: "photomètre d'aérosol PAP 612", img: "images/image/m2.jpg", description: "Le photomètre d'aérosol PAP 612 est conçu pour l'analyse de la concentration de particules dans les aérosols, l'extinction/diffusion de la lumière à deux longueurs d'onde, mieux adapté aux tests de purge.Plage de mesure de la concentration des particules : min. 2g/m3. Raccord de tuyau, entrée/sortie : 28 mm (diamètre nominal) ; grandeurs mesurées : extinction, diffusion de la lumière ; Longueur d'onde de la source lumineuse : 470 nm (bleu), 630 nm (rouge) PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Système d'échantillonnage microbien à distance", img: "images/image/102.jpg", description: "Le système d'échantillonnage microbien à distance EMTEK R100 est conçu pour la surveillance des processus des zones critiques ISO 5 (par exemple, isolateurs, sorbonnes LAF, BSC, chambres BFS, lignes de remplissage, etc.) en contrôlant à distance les échantillonneurs d'aérosols (RAS). Débit d'échantillonnage contrôlé : 28,3 et 100 l/min. Écran LCD avec écran tactile ; Télécommande IR 5 canaux (Démarrage, Arrêt, Pause). L’étalonnage de l’échantillonneur est disponible sur nos équipements. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Capteur présente stabilité et qualité", img: "images/image/101.jpg", description: "Le capteur présente stabilité et qualité, une précision de test élevée, une large plage de mesure et un affichage numérique clair et facile à utiliser. Le capteur d'humidité et de température TH-30R est largement utilisé dans les domaines de l'électronique, de la pharmacie, de la biotechnologie, de l'aérospatiale et d'autres domaines de fabrication, ainsi que dans les départements de recherche scientifique. Plage de mesure : température -20 ～ 80 ℃, humidité 0-100 % RH. Résolution de température : 0,1 ℃, humidité 0,1 % RH. Précision : température ±0,3 ℃, humidité ±3 % HR. Degré de protection IP65. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "FSC-IV", img: "images/image/105.jpg", description: "FSC-IV est un échantillonneur d'air à haute efficacité conçu selon le principe de l'impact des particules à trous multiples et de l'échantillonnage isocinétique. Fabriqué en aluminium de qualité aéronautique. Vitesse d'échantillonnage : 100 l/min, nombre d'échantillons : 0,01 - 6,0 m3 . L’étalonnage de l’échantillonneur est disponible sur nos équipements. " },
    { nameImg: "Contrôleur d'échantillonneur d'air", img: "images/image/106.jpg", description: "Le contrôleur d'échantillonneur d'air EMTEK Versatile 100 (V100) offre un haut niveau de polyvalence pour la surveillance microbiologique de la qualité de l'air dans les environnements et systèmes critiques. Utilisé en conjonction avec les échantillonneurs EMTEK R2S, RAS et RCG. Fabriqué en aluminium et acier inoxydable 316. " },
    { nameImg: "Enregistreur de données de température", img: "images/image/114.jpg", description: "Cet enregistreur de données de température, d'humidité et de point de rosée compatible WiFi peut surveiller à distance l'environnement de -20 à +60°C (-4 à +140°F) et de 0 à 100 %. Les données sont téléchargées périodiquement à l'aide d'un réseau Wi-Fi standard vers Clouds FilesThruTheAir ou sur un seul PC avec un seul hôte." },
    { nameImg: "Technologie avancée basée sur un microcontrôleur", img: "images/image/109.jpg", description: " Modèle portable/de bureau pour les environnements difficiles. Il est très précis et possède une haute résolution.    " },
    { nameImg: "Calibrateur à bloc sec", img: "images/image/110.jpg", description: "Calibrateur à bloc sec de précision Nagman UNFC-100E avec inserts thermiques multi-trous remplaçables. Idéal pour l'étalonnage rapide sur site des thermomètres à résistance, thermocouples, thermomètres bimétalliques, interrupteurs de température, indicateurs et autres instruments dans les applications industrielles et marines.   " },
    { nameImg: "Compteurs d'eau multi-jets", img: "images/image/111.jpg", description: "Les compteurs d'eau multi-jets en plastique de la série WPT sont des compteurs d'eau mécaniques qui affichent la consommation totale d'eau en gallons et éventuellement en m3. Ils sont disponibles en plusieurs tailles et disposent de connexions NPT ou BSPT en option. Ces débitmètres en plastique peuvent être utilisés dans les systèmes d'eau potable, dans certains environnements corrosifs ou lorsqu'un débitmètre d'eau peu coûteux est souhaité.    " },
    { nameImg: "Débitmètre à ultrasons DWYER UFB pour liquides homogènes", img: "images/image/112.jpg", description: "Pour la mesure de débit sans contact dans les canalisations. Ce modèle durable permet à l'utilisateur de monter le transmetteur sur une surface ou un tuyau. Facile à utiliser, de conception compacte et légère, il est conçu pour les équipements utilisant des liquides parfaitement homogènes et ne contenant pas d'entrefer. Application : liquides homogènes ne contenant pas plus de 3 % de bulles ou de particules d’air et capables de propager des ondes ultrasonores.    " },
    { nameImg: "Compteur stationnaire pour mesurer la concentration de particules", img: "images/image/m8.jpg", description: "Les compteurs de particules MIRKIP R210 sont conçus pour les systèmes de surveillance et de validation des salles blanches. Vitesse d'échantillonnage 2,83 l/min ; Mesure sur 2 canaux simultanément (en option jusqu'à 6) ; Les tailles des particules mesurées sont de 0,3 à 10 microns ; L'inscription au registre national du SI de la Fédération de Russie est prévue pour mai 2024. L’étalonnage du compteur est disponible sur nos équipements. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },       
    { nameImg: " DWYER ILA Capteur de température IR en ligne  ", img: "images/image/aa.jpg", description: "sortie de courant ou de thermocouple. Le signal à 2 fils est compatible avec presque tous les indicateurs, contrôleurs, enregistreurs graphiques, enregistreurs de données, etc., sans avoir besoin d’une interface spéciale ou d’une conversion de signal." },
    { nameImg: "  Brides GB9119-88  ", img: "images/image/437606952_122104034696283081_6834710937348255728_n.jpg", description: "Aucune pièce mobile, tuyau droit minimal requis, aucune interférence de débit, protocoles Modbus et BACnet en option. Application : mesure du débit de liquide contaminé ; mesure du débit de liquides conducteurs; traitement de l'eau et des eaux usées; systèmes industriels; système d'irrigation; applications de télémétrie." },
    { nameImg: " H250 M40", img: "images/image/438095710_122104030244283081_2654207385695985221_n.jpg", description: "Le H250 M40 est le rotamètre standard pour les secteurs des procédés et de la fabrication OEM. Le rotamètre combine la mesure mécanique du débit de liquides et de gaz avec des capacités de communication de haute technologie. Le concept modulaire de l'appareil permet une installation ultérieure." },
    { nameImg: " Débitmètre magnétique ", img: "images/image/437728493_122104015580283081_8552013130688160510_n.jpg", description: "Le débitmètre magnétique pour lisier est spécialement conçu pour les conditions de fonctionnement difficiles telles que les pâtes et papiers, les boues, les mines, les usines de traitement des eaux usées et d'autres industries." },
    { nameImg: " Débitmètre vortex ", img: "images/image/438059536_122104014584283081_1259264854235727802_n.jpg", description: "Le débitmètre vortex compensé en température et en pression adopte le principe du vortex Karman, le capteur est doté d'un cristal piézoélectrique intégré et d'une fonction de mesure de la température et de la pression intégrée, qui peut mesurer la température et la pression du gaz, fournissant un débit volumétrique ou massique plus précis." },
    { nameImg: "Conduit d’air en tissu avec membrane TEX-Heat&Cool  ", img: "images/image/500.jpg", description: "S’il est nécessaire de passer du mode chauffage au mode refroidissement à l’intérieur de la pièce au cours de la journée, de la saison ou de l’année, cela posait auparavant un problème, car les solutions traditionnelles ne s’appliquent qu’à une plage de température limitée. Avec les solutions standard de distribution d’air, les ingénieurs font la moyenne des valeurs lorsque le delta T est moins extrême, ou en utilisant des diffuseurs ou des buses coûteux qui sont contrôlés par une électronique encore plus coûteuse." },
    { nameImg: " Fileté 1/2″ gaz pour NPT (L5-2600)  ", img: "images/image/438118518_122104013042283081_508593412934496489_n.jpg", description: "Conçus pour être utilisés dans des environnements potentiellement explosifs grâce à leur conception antidéflagrante. Plages de mesure : air : de 1,6 nl/h à 5 000 nl/h (T=20°C ; P=1013 mbar a), eau : de 0,1 l/h à 160 l/h ; Précision : ±1 % de la limite supérieure de mesure ; Degré de protection du boîtier : IP65 (IP67 sur demande) ; Sortie analogique : 4-20 mA, superposée au signal de communication du protocole Hart (sur demande)." },
    { nameImg: " Rotamètres de la série ASA C  ", img: "images/image/437557514_122104011626283081_434870021371696510_n.jpg", description: "Les rotamètres de la série ASA C peuvent être fournis dans une version avec microprocesseur (technologie 2 fils) avec écran LCD à 8 chiffres indiquant le débit actuel, total et en pourcentage, signal de sortie 4-20 mA avec protocole HART (alimentation 24 Vdc ± 10 %) et interface Profibus PA (sur demande). Peut être installé verticalement pour un flux ascendant (standard) ou horizontalement (sur demande)." },
    { nameImg: " Collecteur ouvert NPN avec impulsions carrées.", img: "images/image/438242288_122104010120283081_8368791229621951965_n.jpg", description: "N'utilise pas de capteurs magnétiques, adaptés aux tuyaux 1-1/2-40˝ (38,1-1016 mm), sortie impulsion ou 4-20 mA. Idéal pour surveiller le débit d'eau dans les systèmes d'irrigation et pour automatiser les systèmes de refroidissement des bâtiments. Utilise une détection inductive lorsque les pales de la roue à aubes tournent. Cette technologie n'utilise pas d'aimants pour permettre la surveillance des faibles débits et il n'y a aucun contact de matériaux magnétiques avec le flux. La roue d'hélice, l'arbre et les roulements sont facilement remplacés sur le site d'installation." },
    { nameImg: " Filetage femelle 1/8” NPT ", img: "images/image/437535408_122104008086283081_595790235777192715_n.jpg", description: "Un outil économique pour mesurer toutes sortes de débits. Idéal pour les applications impliquant le débit d'air et d'eau, le traitement de films, les procédés chimiques, la chromatographie en phase gazeuse, les produits pharmaceutiques, l'alcool et le tabac, les pâtes et papiers et la fabrication de semi-conducteurs." },
    { nameImg: " liquides compatibles ; Précision : ±2 % de la pleine échelle ", img: "images/image/437158427_122104006622283081_2980598038582317883_n.jpg", description: "Fonctionne très bien avec les liquides sombres et opaques. Peut être installé en ligne sur un pipeline ou intégré à un panneau. Il a une large gamme d'applications : pour contrôler le débit de liquides de refroidissement dans les échangeurs de chaleur, les compresseurs, les épurateurs, ainsi que pour contrôler la consommation d'eau dans divers processus, pour contrôler la vitesse de remplissage et de vidange des réservoirs, des châteaux d'eau, etc." },
    { nameImg: "Dwyer SCFM AIR ", img: "images/image/438818538_122104005944283081_8998362779706654104_n.jpg", description: "Conduites principales, gaz, liquides et huiles. Mesure les débits de gaz avec une précision de ±5 % et de liquides de ±2 % de la pleine échelle. La construction robuste en aluminium garantit une bonne compatibilité et peut résister à des pressions du système allant jusqu'à 200 bars. Une connexion 1/4″ NPT ou 1/2″ NPT est disponible. L'appareil est livré pré-calibré pour une installation en ligne horizontale." },
    { nameImg: "Capteur de température de surface", img: "images/image/top-al3-ton-al3-ttj-al3-ttk-al3_v1.png", description: "Thermocouple de type J (fer-constantan) en acier 1.4541 d’un diamètre de 5 mm, avec câble Cu ou avec câble thermocouple : 2×0,22 mm2 en fibre de verre tressée métallique. Milieu de mesure de la température : Surfaces solides" },
    { nameImg: "Capteur de température de surface TTK-AL2 Type K THA -40°C à 400°C Classe 2 ", img: "images/image/004.png", description: "Thermocouple de type K (chromel-alumel) en acier 1.4541 d’un diamètre de 5 mm, avec câble Cu ou avec câble thermocouple : 2×0,22 mm2 en isolation en fibre de verre tressée métallique." },
    { nameImg: "filetage externe ½” NPT ", img: "images/image/438030277_122104038686283081_5410704791445350235_n.jpg", description: "Il fonctionne dans la plage de 0 à 70 m/s et n'a pas d'analogue de ce type. Il est inscrit au registre national des instruments de mesure et est soumis à vérification. Applications : surveillance du débit d'échappement, contrôle du débit d'air de séchage, mesures de la vitesse de l'air dans les systèmes CVC, surveillance de la ventilation et des gaz d'échappement, etc." },
    { nameImg: "DWYER ILA Capteur de température IR en ligne", img: "images/image/001.jpg", description: "Ils mesurent des températures de 0 à 500°C et donnent un signal de sortie linéarisé de 4 à 20 mA, de 0 à 50 mV DC. Le signal à 2 fils est compatible avec presque tous les indicateurs, contrôleurs, enregistreurs graphiques, enregistreurs de données, etc., sans avoir besoin d’une interface spéciale ou d’une conversion de signal." },
    { nameImg: "Compteur de particules aéroportées portable", img: "images/image/f6.jpg", description: "Compteur de particules aéroportées portable avec imprimante intégrée. Les compteurs de particules TSI AEROTAK 9350 sont conçus pour surveiller les zones propres et les usines pharmaceutiques.Vitesse d'échantillonnage 50 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,3 à 25 microns. Temps de fonctionnement continu 3 heures. La vérification et l’étalonnage du compteur à l’aide de notre équipement sont disponibles." },
    { nameImg: "Compteur portatif", img: "images/image/f7.jpg", description: "Vitesse d'échantillonnage 2,83 l/min ; Mesure sur 3 canaux simultanément ; Les tailles des particules mesurées sont de 0,3 à 25 microns ; Inclus dans le registre d'État du SI de la Fédération de Russie. La vérification et l’étalonnage du compteur à l’aide de notre équipement sont disponibles." },
    { nameImg: " compteurs de particules TSI AEROTAK 9306 ", img: "images/image/f8.jpg", description: "Vitesse d'échantillonnage 2,83 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,3 à 10 microns. La vérification et l’étalonnage du compteur à l’aide de notre équipement sont disponibles." },
    { nameImg: "capteur de vitesse de l'air WS30R", img: "images/image/f9.jpg", description: "Plages de mesure : 0-1 m/s, 1-20 m/s ; précision : ± 0,1 m/s ; température de fonctionnement : de 0°C à 50°C ; humidité relative : ˂85% RH." },
    { nameImg: "microbiologique", img: "images/image/f10.jpg", description: "L'échantillonneur microbiologique est résistant à la corrosion et facile à stériliser. Sépare les particules viables par taille pour la collecte et l'analyse des bactéries aérobies et des champignons. Portable, pèse moins de 9 kg, économique, facile à utiliser. L’étalonnage de l’échantillonneur est disponible sur nos équipements." },
    { nameImg: "Transducteur thermoélectrique", img: "images/image/impls_178_1_178.png", description: "Thermocouple de type N (nihrosyl-nisyl) en OMEGACLAND XL avec une longueur de coque et un diamètre de 3 ou 6, avec tête de commutation, IP55 (acier inoxydable BEG ; aluminium NA, IP65 en option)." },
    { nameImg: "BioTrak", img: "images/image/f3.jpg", description: "BioTrak détermine le nombre total de particules viables en temps réel et inclut une technologie de fluorescence induite par laser (LIF) brevetée et validée par TSI pour déterminer la viabilité des particules.Taux d'échantillonnage des aérosols 28,3 l/min ; Mesure sur 6 canaux simultanément ; Les tailles des particules mesurées vont de 0,5 à 25 microns. L’étalonnage du compteur est disponible sur nos équipements." },
    { nameImg: "Spectromètre d'aérosol LAP 322", img: "images/image/f4.jpg", description: "Le spectromètre d'aérosol LAP 322 est conçu pour analyser la répartition des particules dans les aérosols par taille et concentration, lumière monochrome.Débit d'échantillon : 3,0 l/min (0,1 l/min pour les mesures) ; plage de mesure de la taille des particules : 0,2 … 40 (0,2 … 5 | 0,24 … 10 | 0,7 … 40) µm ; résolution de mesure de la taille des particules : max. Taille de 128 canaux (réglable)." },
    { nameImg: "INSTRUMENTATION DE DEYER", img: "images/image/sq-001.jpg", description: "Depuis 1931, Dwyer Instruments produit des capteurs, des relais et des instruments. En Russie, ces appareils sont officiellement fournis depuis 2008. Pression, qualité de l’air, débit, température, niveau, contrôle de processus, instrumentation, vannes d’arrêt et de régulation." },
    { nameImg: "DILUANTS POUR AREROSOLS", img: "images/image/6.png", description: "Le dilueur d'aérosols est conçu pour diluer avec précision les aérosols et obtenir une certaine concentration de particules." },
    { nameImg: "COMMUTATEUR DE TEMPERATURE", img: "images/image/2.png", description: "Un relais de température est un appareil sensible qui contrôle le fonctionnement d'un équipement lorsque la température mesurée change à l'endroit où il effectue des mesures. Les capteurs correspondants sont soit un élément bimétallique, soit un cylindre sensible à la chaleur, dont la pression change proportionnellement au changement de température." },
    { nameImg: "RELAIS DE DEBIT", img: "images/image/1.png", description: "Les commutateurs de débit sont conçus pour surveiller en permanence la présence de mouvements de gaz, d'eau et de divers fluides liquides dans une canalisation/conduit d'air." },
    { nameImg: "CAPTEURS DE NIVEAU", img: "images/image/9.png", description: "Idéal pour les eaux souterraines et les puits, la protection contre la foudre ou la sécurité intrinsèque. Fabriqué pour un fonctionnement sans problème et sans entretien pendant plusieurs années dans les conditions de fonctionnement les plus sévères." },
    { nameImg: "CAPTEURS MURAL DE MONOXYDE DE CARBONE DE CO ET DE NO2", img: "images/image/11.png", description: "Capteur mural de monoxyde de carbone CO et de dioxyde d'azote NO2 DWYER GSTA/GSTC" },
    { nameImg: "DEBITMETRES", img: "images/image/34.png", description: "Appareils pour mesurer le débit volumétrique de gaz ou de liquides par unité de temps. La méthode de mesure du débit de liquides à l'aide d'un rotamètre est peu coûteuse, mais précise et fiable et, surtout, adaptée même à de faibles pressions de fonctionnement." },
    { nameImg: "TUYAUX CHAUFFES ELECTRIQUEMENT", img: "images/image/sq-020.jpg", description: "Tuyaux flexibles de haute qualité équipés d’un chauffage électrique. Les produits sont utilisés dans diverses industries où il est nécessaire de chauffer le liquide distillé tout en maintenant une température constante. La plage de température de fonctionnement est de 30 à 250 °C, jusqu’à 350 °C dans les versions spéciales." },
    { nameImg: "CAPTEURS DE PRESSION", img: "images/image/4.png", description: "Capteurs de pression conçus pour mesurer la pression dans les systèmes d'ingénierie avec un signal de sortie analogique 4-20 mA, 0-10/5 V ou numérique." },
    { nameImg: "CAPTEURS DE VITESSE DE L'AIR", img: "images/image/3.png", description: "Capteurs fixes utilisant le principe du fil chaud, basé sur la dépendance de la température de la masse chauffée sur la vitesse d'écoulement." },
    { nameImg: "POMPES A PRESSION", img: "images/image/7.png", description: "Les pompes à pression sont idéales pour une utilisation mobile. Leur faible poids et leur conception compacte rendent les appareils faciles à transporter directement sur le site de mesure. Ne nécessite pas de source d'alimentation supplémentaire. " },
    { nameImg: "COUVERTURES THERMIQUES ET FOURS POUR L'INSTRUMENTATION", img: "images/image/31.jpg", description: "Les tuyauteries, les systèmes de réservoirs et les équipements industriels qui nécessitent un accès régulier ont besoin de structures d’isolation thermique amovibles et amovibles – des couvertures thermiques. Le choix des matériaux d’isolation thermique et d’une couche de couverture pour les couvertures thermiques dépend des exigences techniques d’un système d’isolation thermique particulier." },
    { nameImg: "SURVEILLANCE DES SALLES BLANCHES", img: "images/image/32.jpg", description: "Dans les systèmes de contrôle informatique, la capacité de connecter des capteurs de divers paramètres requis est généralement utilisée. Cette section fournit l’équipement nécessaire à la validation, à l’attestation et au suivi de l’état des salles blanches des classes A à D." },
    { nameImg: "CAPTEURS D'HUMIDITE ET DE TEMPERATURE", img: "images/image/8.png", description: "Un tel dispositif est utilisé pour l’automatisation et la répartition, divers types de locaux et de serres, ainsi que pour les processus technologiques. Le capteur de température et d’humidité a un signal de sortie, généralement il est de 4... 20 mA ou 0... 10 C." },
    { nameImg: "TISSU DE DECOUPE LASER", img: "images/image/33.jpg", description: "Nous offrons le service de découpe du tissu et du cuir sur une machine laser. Un mince faisceau laser découpe le matériau avec une grande précision. La coupe est fine et soignée. Une légère fonte des bords empêche le tissu de s’étaler." },
    { nameImg: "COMPTEURS DE PARTICULES MANUEL", img: "images/image/10.jpg", description: "Compteur portatif pour mesurer la concentration de particules d’aérosol. Les compteurs de particules TSI AEROTAK 9303 sont conçus pour la surveillance des salles blanches et de la production pharmaceutique." },
    { nameImg: "THERMOMETRES", img: "images/image/5.png", description: "Thermomètres avec une variété de méthodes de mesure de la température, fabriqués à partir de matériaux de haute qualité pouvant résister à une utilisation dans des conditions de travail extrêmes" },
    { nameImg: "CAPTEURS DE PRESSION", img: "images/image/13.jpg", description: "Le FKC avec raccord process à bride peut être monté directement sur 3 ou 5 boîtes de vannes.Plage de mesure : ±10 millibar à ±30 bar (14 échelles) ; Pression statique : jusqu’à 300 bar (1035 bar sur les modules spéciaux) ; Signal de sortie : 4-20 mA : Amplitude de mesure : 100 :1 ; précision : 0,065 % ; Raccord process : 1/4 »-18NPT (brides ovales sur demande)" },
    { nameImg: "CONVERTISSEURS DE FREQUENCE", img: "images/image/14.jpg", description: "L’onduleur de la série PT300 peut fonctionner dans une large plage de régime et de couple. Des plates-formes matérielles de haute qualité, une technologie de production scientifique et des tests d’équipement complets rendent le produit plus stable et plus fiable." },
    { nameImg: "GENERATEURS D'AREROSOLS", img: "images/image/15.jpg", description: "Le générateur de pulvérisation d’aérosol ATM 226 est particulièrement adapté aux applications en salle blanche et aux tests de filtres conformément aux normes internationales." },
    { nameImg: "MANOMETRES", img: "images/image/18.jpg", description: "Les manomètres sont conçus pour mesurer la pression (excessive, absolue, différentielle) et la différence de pression entre les liquides et les gaz. Tous les appareils de mesure de pression peuvent être divisés selon le principe de fonctionnement, puis nous obtenons des dispositifs de transmission à distance, à liquide, à ressort, à piston de poids mort et à distance." },
    { nameImg: "CALIBRATEUS DE TEMPERATURE", img: "images/image/19.jpg", description: "Calibrateurs de température fabriqués en Allemagne et en Inde. Avec inserts infrarouges, dry-block et liquides. Ils sont utilisés pour l’étalonnage d’instruments conçus pour la mesure de la température." },
    { nameImg: "REGULATEURS DE TEMPERATURE ET  THERMOSTATS", img: "images/image/27.jpg", description: "Depuis 1960, l’entreprise allemande ALRE produit des régulateurs de température de l’air, des régulateurs de température au sol, des régulateurs de température radio sans contact, des servomoteurs de vannes thermiques, des thermostats mécaniques, des capteurs de point de rosée et des systèmes de contrôle de systèmes de climatisation." },
    { nameImg: "CONDUITS D'AIR TEXTILES", img: "images/image/21.jpg", description: "À l’aide de conduits d’air en tissu, il est possible de contrôler la distribution de l’air dans la pièce avec une précision exceptionnelle. Des calculs techniques et une qualité élevée font des conduits d’air textiles OLIL une solution idéale en termes de rapport qualité/prix." },
    { nameImg: "MAISON D'INTELLIGENTE", img: "images/image/23.jpg", description: "À partir de seulement 100 000 roubles, vous pouvez devenir propriétaire d’une maison intelligente. Protection contre les fuites, contrôle du chauffage par le sol, arrêt automatique de l’eau lorsque vous partez en vacances, lumières intelligentes, contrôle de la climatisation, protection de la maison en votre absence. Tous ensemble et en pièces détachées pour tous les budgets et tous les besoins." },
    { nameImg: "COMPTEURS DE PARTICULES", img: "images/image/30.jpg", description: "TSI Incorporated est une société américaine bien connue spécialisée dans le développement et la production d’instruments de mesure de précision pour l’étude des aérosols, des flux d’air, de la qualité de l’air intérieur, de la dynamique de l’écoulement des fluides et de la détection des environnements à risques biologiques." },
    { nameImg: "INTERPHONES D'ELITE GUINAZ", img: "images/image/25.jpg", description: "Interphones espagnols pour bureaux et chalets, interphones IP, panneaux d’appel couleur." },
    { nameImg: "AUTOMATISATION INDUSTRIELLE ET DISPATCHING", img: "images/image/24.jpg", description: "Automatisation d’entreprises industrielles, d’hôtels, de CVC, de contrôle du CO dans les parkings, conception de systèmes d’automatisation – de nombreux projets réalisés. L’objectif de chaque projet est de réduire la consommation d’énergie et de la rentabiliser à court terme." },
    { nameImg: "EQUIPEMENT DE NETTOYAGE", img: "images/image/20.jpg", description: "La société américaine Goodway produit des aspirateurs pour salles blanches, des aspirateurs industriels, des équipements pour le nettoyage des conduits de ventilation, des échangeurs de chaleur." },
    { nameImg: "LABORATOIRE DE METROLOGIE", img: "images/image/26.jpg", description: "Le laboratoire de métrologie est accrédité en termes de pression et de température. En plus des services de vérification classiques, le laboratoire est engagé dans l’externalisation des services métrologiques des entreprises, ce qui leur permet de réduire les investissements opérationnels et d’investissement en métrologie." },
    { nameImg: "CAPTEURS DE TEMPERATURE", img: "images/image/29.jpg", description: "Production en interne de thermocouples et de thermocouples à résistance sur commande à partir de 3 jours. Tout type de thermocouple : K, J, N, R, S, B, T, Pt100, Pt500, Pt1000, Ni100, Ni1000. Approvisionnement garanti en capteurs de température de fabricants étrangers." },
    { nameImg: "PRESSOSTAT", img: "images/image/28.jpg", description: "Une large gamme de capteurs de pressostat, y compris des modèles antidéflagrants dotés d’une plaque à orifice de précision et détectant les pressions positives, négatives et différentielles." },
    { nameImg: "TRANSDUCTEUR THERMOELECTRIQUE", img: "images/image/a.png", description: "Thermocouple de type R (platinerhodium-platine) en acier 1.4541 dans une gaine SAP en céramique avec une longueur de boîtier sur demande et un diamètre de 5, 6, 8, 10 mm, avec une tête de commutation, IP54.Mesure de la température Environnement : Air, Liquide" },
    { nameImg: " Convertisseur thermoélectrique pour l’industrie agroalimentaire TTKGNS ", img: "images/image/bb.png", description: "Thermocouple de type K (chromel-alumel) avec gaine en acier 1.4541 d’une longueur de coque de 30 à 1000 mm et d’un diamètre de 6,8 mm, avec tête de commutation, IP65." },
    { nameImg: " Capteur de température programmable DWYER série 652-0 ", img: "images/image/cc.png", description: "Peut fonctionner avec un capteur de température couramment utilisé, un signal millivolt et donner un signal de sortie de 4 à 20 mA. Le type et la plage d’entrée sont facilement programmables à l’aide d’un PC et d’un simple logiciel Windows vendu séparément." },
    { nameImg: " Convertisseur thermoélectrique TTKI-3 Type K THA -40 à 700 °C Classe 2 ", img: "images/image/dd.png", description: "Thermocouple de type K (chromel-alumel) en acier 1.4541 avec des longueurs de coque de 50 à 2000 mm et des diamètres de 6, 8, 10, 12 mm, avec tête de commutation, IP54." },
    { nameImg: " Transducteur thermoélectrique WTTKGN-6 Type K THA -40°C à 550°C Classe 2 ", img: "images/image/ee.png", description: "Thermocouple de type K (chromel-alumel) en acier 1.4541 avec des longueurs de coque de 80 à 1500 mm et des diamètres de 8, 9, 11, 12 mm, avec tête de commutation, IP65." },
    { nameImg: " Transducteur thermoélectrique avec confinement céramique TTBCC-22 Type B TPR 600 à 1800 °C Classe 3 ", img: "images/image/ff.png", description: " Thermocouple de type B (platinerhodium) en acier 1.4841 et céramique en mullite 610 ou corindon 799 d’une longueur de coque de 300 à 2000 mm et d’un diamètre de 24 ou 15 mm, avec tête de commutation, IP53." },
    { nameImg: "Photomètre d'aérosol PAP 610", img: "images/image/f5.jpg", description: "Le photomètre d'aérosol PAP 610 est conçu pour analyser la concentration massique de particules dans les aérosols, le mieux adapté pour caractériser le brouillard d'huile lors des tests de purge.Vitesse d'échantillonnage : de 0,1 ... 8 l/min ; Plage de mesure de la concentration de particules : 0,02 - 3 g/m3. L’étalonnage du compteur est disponible sur nos équipements." },
    { nameImg: "Capteurs de température du fabricant", img: "images/image/f2.jpg", description: "La société OLIL est l'un des principaux fabricants russes de convertisseurs thermiques et de thermocouples jusqu'à 2300 °C . Des équipements modernes et une équipe d'ingénieurs expérimentés nous permettent de produire des capteurs d'une qualité bien supérieure à celle des appareils similaires d'autres usines" },
    { nameImg: "Capteur à sécurité intrinsèque pour la mesure de la température ", img: "images/image/impls_226_1_226.png", description: "Thermocouple de type K (chromel-alumel) en acier 1.4541 d’une longueur de gaine de 50 à 1000 mm et d’un diamètre de 3, 4, 5, 6, 8 mm, avec câble Cu ou avec un câble thermocouple de 0,22 mm2 en acier tressé isolant en fibre de verre ou avec un câble Cu ou avec un câble thermocouple de 0,22 mm en isolation silicone.    " },
    { nameImg: "capteurs de vitesse d'air MVTGE374EX", img: "images/image/f1.jpg", description: "Les capteurs de vitesse d'air MVTGE374EX fonctionnent selon un principe innovant de tube de Pitot qui garantit une précision à basse vitesse d'air et de meilleures performances à haute température. Insensible à la poussière et à la saleté, boîtier antidéflagrant." },
    { nameImg: "Echantillonneur d'air microbien portable", img: "images/image/103.jpg", description: "L'échantillonneur d'air microbien portable P100 d'EMTEK est conçu pour l'échantillonnage d'air microbien. Deux taux d'échantillonnage avec processeur : 28,3 LPM (1 CFM) et 100 LPM. Écran tactile LCD avec interface. Taux d'échantillonnage contrôlables : 28,3 et 100 LPM. L’étalonnage de l’échantillonneur est disponible sur nos équipements. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "FSC-V", img: "images/image/104.jpg", description: "Le FSC-V est un échantillonneur d'air microbiologique haute performance. Il est conçu selon le principe de l’impact de particules multi-jets et de l’échantillonnage isocinétique. Fabriqué en acier inoxydable SUS304. Vitesse d'échantillonnage : 100 l/min, nombre d'échantillons : 0,01 - 9,0 m3 . L’étalonnage de l’échantillonneur est disponible sur nos équipements.  PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "Echantillonneur rectangulaire SYS 529", img: "images/image/107.jpg", description: "Échantillonneur rectangulaire SYS 529 pour tests d'étanchéité et tests d'intégrité conformément à la norme ISO 14644-3 pour un débit d'aérosol de 28,3 l/min. Raccord de tuyau en sortie Ø 8 mm. Débit d'échantillon : de 31 à 47 cm/sec ; Débit d'entrée : Ø 8 mm. " },
    { nameImg: "Echantillonneur gravimétrique GMS 141", img: "images/image/108.jpg", description: "L'échantillonneur gravimétrique GMS 141 permet de déterminer facilement la teneur en huile des séparateurs de brouillard d'huile avec filtres plats sur les bancs d'essais moteurs. Prélèvement d'aérosols sur filtre chauffé (max. 120 °C). Avec capteur de température et de débit." },
    { nameImg: "Capteur numérique portable de température et d'humidité ", img: "images/image/100.jpg", description: "Le capteur numérique portable de température et d'humidité TH-40 affiche la température et l'humidité numériquement. Il se distingue par sa qualité et sa haute précision, fiable et facile à utiliser. C'est un appareil idéal pour les usines pharmaceutiques et le service de contrôle qualité. Plage de mesure : température de -24 ~ 125 ℃, humidité de 0 à 100 % RH ; Précision : température ±0,3 ℃, humidité ±3 % RH ; Conditions de travail : température de 0～50℃, humidité＜85% HR. PRIX SUR DEMANDE Délai de livraison : à partir de 8 semaines" },
    { nameImg: "DWYER 490W Manomètre différentiel hydraulique sans fil Bluetooth", img: "images/image/w4.jpg", description: "DWYER 490W Manomètre différentiel hydraulique sans fil Bluetooth pour gaz et liquides Le manomètre différentiel hydraulique sans fil de la série 490W est le manomètre différentiel le plus précis et le plus facile à utiliser du marché. Grâce aux transducteurs sans fil et à la facilité de transport, l'opérateur peut contrôler et équilibrer seul un système hydraulique en moins de temps qu'avec les instruments d'équilibrage hydraulique traditionnels. Le manomètre de la série 490W utilise la technologie de communication mobile Bluetooth pour contrôler la pression différentielle et le débit du fluide à travers trois vannes différentes. Le terme sans fil implique que le manomètre n'a pas d'éléments structurels à transporter, à fixer à l'équipement ou à vidanger. Applications : gaz et liquides compatibles ; précision : 2% sur l'échelle, ± 0,5 psi ; plage de température : -10 à 60°C ; raccord de service : deux filetages mâles 1/4 ̋ NPT. Fabriqué aux Etats-Unis. Fabricant : DWYER, modèle 490 W Prix sur demande  Délai de livraison à partir de 8 semaines"},
    { nameImg: "Convertisseur de fréquence DELTA VFD-VE", img: "images/image/w5.jpg", description: "Convertisseur de fréquence DELTA VFD-VE Convertisseur de fréquence DELTA VFD-VE La série VFD-VE est une nouvelle génération de convertisseurs de fréquence multifonctionnels de type micro. Cette série utilise le FOC (Field Oriented Control), un algorithme de contrôle vectoriel beaucoup plus précis. Il peut être utilisé à la fois pour le contrôle de la vitesse et de la position, comme un servomoteur. Le terminal d'E/S multifonctionnel a été étendu pour répondre aux différents besoins des utilisateurs. Il est également équipé d'un logiciel PC basé sur Windows pour la gestion des paramètres et la surveillance dynamique. C'est une excellente solution pour la régulation de la charge. Elle peut fournir 150% du couple nominal à vitesse nulle et peut avoir des fonctions de contrôle de position point à point et de distance relative, ce qui en fait le meilleur choix pour les systèmes de contrôle industriels complexes. Fabricant DELTA, modèle VFD-VE Prix sur demande Délai de livraison à partir de 8 semaines"},
   


    

  ]
  console.log(materiels.length)

  return (
    <div className="">


      <div id="page">
        <nav class="colorlib-nav" role="navigation">
          <div class="top-menu">
            <div class="container">
              <div class="row">
                <div class="col-sm-7 col-md-9">
                  <div id="colorlib-logo"><a href="/"><img src="images\image\logo.jpg" style={{ width: '150px', height: '50%', }} /></a></div>
                </div>
                <div class="col-sm-5 col-md-3">
                  <div>
                    <p>+221 70 619 42 41 / 33 870 65 31<br /> du lundi au vendredi: de 9h00 a 17h:00<br />service-clients@msedi-internationl.com</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sale">
            <div class="container">
              <div class="row">
                <div class="col-sm-8 offset-sm-2 text-center">
                  <div class="row">
                    <div class="owl-carousel2">
                      <div class="item">
                        <div class="col">
                          <h3><a href="#">PLONGEZ DANS L'UNIVERS DE MSEDI-INTERNATIONAL, </a></h3>
                        </div>
                      </div>
                      <div class="item">
                        <div class="col">
                          <h3><a href="#">OÙ NOUS VOUS OFFRONS UN ACCÈS PRIVILÉGIÉ À UNE GAMME EXCEPTIONNELLE DE MATÉRIAUX DE QUALITÉ SUPÉRIEURE.</a></h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>



        <div style={{ height: '100vh', backgroundImage: 'url(images/image/sq-020.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="position-relative w-100">
          <div className="position-absolute text-white d-flex flex-column align-items-center justify-content-center" style={{ top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,.6)' }}>

            <div style={{ width: '50%' }}>

              <h1 className="mb-4 mt-2 font-weight-bold text-center text-white">Instrumentation d’un fournisseur senegalais de premier plan</h1>
              <span className="mb-4 mt-2 font-weight-bold text-center">MSEDI est en partenariat avec OLIL qui est le representant des marques mondiales bien connues : Dwyer, Nuova Fima, SIKA et d’autres en Russie. Chez nous, vous pouvez acheter une grande variété d’instruments pour tous les usages dans notre vaste gamme de contrôle-commande.</span>

            </div>

          </div>


        </div>

        <div class="colorlib-intro">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h2 class="intro">Bienvenue dans le futur de l'instrumentation et de l'automatisation. <br />Bienvenue chez MSEDI.</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="colorlib-product">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6 text-center">
                <div class="featured">
                  <a href="#" class="featured-img" style={{ backgroundImage: 'url(images/image/sq-020.jpg)' }}></a>
                  <div class="desc">
                    <h2><a href="#">TUYAUX CHAUFFES ELECTRIQUEMENT</a></h2>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 text-center">
                <div class="featured">
                  <a href="#" class="featured-img" style={{ backgroundImage: 'url(images/image/sq-001.jpg)' }}></a>
                  <div class="desc">
                    <h2><a href="#">INSTRUMENTATION DE DEYER</a></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="colorlib-product">
          <div class="container">
            <div class="row">
              <div class="col-sm-8 offset-sm-2 text-center colorlib-heading">
                <h2>Catalogue d’instrumentation et d’automatisation</h2>
              </div>
            </div>
            {/** Boucle pour afficher les differentes informations */}
            <div class="row row-pb-md ">

              {
                materiels.slice(0, visibleMaterials).map(materiel => (

                  <div class="col-lg-3 mb-4 text-center">
                    <div class="product-entry border">
                      <div class="prod-img h-[10px] ">
                        <img src={materiel.img} class="img-fluid " alt="" />
                        <div class="overlay ">
                          <p class="text-white"> {materiel.description} </p>
                        </div>
                      </div>
                      <div class="desc w-full ">
                        <h2>{materiel.nameImg}</h2>
                      </div>

                    </div>
                  </div>

                ))
              }

              <div className="col-md-12 text-center mt-3">

                {visibleMaterials < materiels.length && (

                  <button className="btn btn-primary" onClick={loadMore}>
                    Voir plus
                  </button>

                )}
                <button className="btn btn-secondary" onClick={loadLess}>
                  Voir moins
                </button>
              </div>
            </div>


          </div>
        </div>

        <Partenaire />


      </div>

      <div class="gototop js-top">
        <a href="#" class="js-gotop"><i class="ion-ios-arrow-up"></i></a>
      </div>

      <section class="" style={{ backgroundColor: rgba(0, 0, 0, 0.05) }}>
        <footer class="bg-body-tertiary text-center text-md-start">

          <div class="container p-4" >
            <div class="row">

              <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                <h5 class="text-uppercase">MSEDI - INTERNATIONAL</h5>

                <p>
                  MSEDI est en partenariat avec OLIL qui est le representant des marques mondiales bien connues
                  : Dwyer, Nuova Fima, SIKA et d’autres en Russie. Chez nous,
                  vous pouvez acheter une grande variété d’instruments pour tous les usages dans notre vaste gamme de contrôle-commande.
                </p>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text-uppercase">RESEAUX SOCIAUX</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61558492439828&mibextid=ZbWKwL" class="text-body">FACEBOOK</a>
                  </li>

                  <li>
                    <a href="https://www.linkedin.com/in/ahmadou-sarr-a3b255253?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" class="text-body">LINKDIN</a>
                  </li>
                  <li>
                    <a href="https://wa.me/message/G5LURQEJKVSFH1" class="text-body">WHATSAPP</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text-uppercase mb-0">CONTACT</h5>

                <ul class="list-unstyled">
                  <li>
                    <a href="" class="text-body">service-clients@msedi-internationl.com</a>


                  </li>
                  <li>
                    <a href="" class="text-body">+221 70 619 42 41 / 33 870 65 31</a>
                  </li>

                </ul>
              </div>

            </div>
          </div>

          <div class="text-center p-3" style={{ backgroundColor: rgba(0, 0, 0, 0.05) }}>
            © 2023 Copyright:
            <a class="text-body" href="/">msedi-international.com</a>
          </div>

        </footer>
      </section>
      <script src="js/jquery.min.js"></script>

      <script src="js/popper.min.js"></script>

      <script src="js/bootstrap.min.js"></script>

      <script src="js/jquery.easing.1.3.js"></script>

      <script src="js/jquery.waypoints.min.js"></script>

      <script src="js/jquery.flexslider-min.js"></script>

      <script src="js/owl.carousel.min.js"></script>

      <script src="js/jquery.magnific-popup.min.js"></script>

      <script src="js/magnific-popup-options.js"></script>

      <script src="js/bootstrap-datepicker.js"></script>

      <script src="js/jquery.stellar.min.js"></script>

      <script src="js/main.js"></script>

    </div>
  );
}

export default App;
